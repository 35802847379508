<template>
    <div>
        <div class="wrapper">
        <!-- header -->
        <!-- 大網展開menu: header-menu-sec 移除hidden -->
        <!-- 小網展開menu: wrapper 加class="header-nav-open" -->
        <header>
        <div class="pagesize">
            <div class="header-area">
          <h1><router-link to="/"><span class="zh">士林區區域電能管理系統</span><br /><span class="eng">SHILIN Community Energy Management
                System </span></router-link></h1>
            <p class="header-time">更新時間：{{updateTime}}</p>
            <a href="#" class="header-nav-btn"></a>
            <div class="header-nav">
                <a href="./login" class="btn-login" @click="LOGIN_OUT">登出</a>
                <ul class="header-menu-first">
                  <li><router-link to="/">能源地圖</router-link></li>
                  <li><router-link to="/electricity">發用電資訊</router-link></li>
                  <li><a href="#"  class="folder on" >需量反應</a>
                    <ul class="header-menu-sec">
                      <li><router-link to="./bidCalc">投標試算</router-link></li>
                      <li><router-link to="./bidRecord">得標及抑低分配發布</router-link></li>
                      <li><router-link to="./realTime">即時執行情形</router-link></li>
                      <li><router-link to="./history">歷史紀錄</router-link></li>
                      <li><router-link to="./group">群組成員資料</router-link></li>
                      <li><router-link to="./download">下載專區</router-link></li>
                    </ul>
                  </li>
                </ul>
            </div>
            </div>
        </div>
        </header>
        <!-- container -->
        <div class="container">
        <div class="pagesize">
            <ul class="breadcrumbs">
            <li><a href="#">需量反應</a></li>
            <li><span>得標及抑低分配發布</span></li>
            </ul>
            <div class="area bg-white margin02">
            <div class="padding03">
                <!-- <h2 class="boxtitle margin02">投標試算與建議</h2> -->
                <div class="history-area box-border box-round">
                <div class="flex-box flex-1 padding02 section-box bid-info">
                    <div class="section-inner section-2">
                    <div class="weather-box">
                        <h3 class="subtitle margin">明日天氣預測</h3>
                        <div class="weather-box-inner">
                        <p class="tempNum"><span>{{nextdayWeather[0]}}</span>&nbsp;℃</p>
                        <div class="imgbox">
                            <img :src="nextdayWeather[2]">
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="section-inner section-2">
                    <div class="flex-1">
                        <h3 class="subtitle f-left">{{bidnote.title}}{{bidnote.do}}倒數</h3>
                        <a class="f-right btn-small btn-goto bg-kahki " href="https://dbp.taipower.com.tw/TaiPowerDBP/Portal/Login" target="_blank">投標連結</a>
                        <div class="clr margin"></div>
                        <p class="texts-contdown">{{countdownTimer(getTimenow)}}</p>
                    </div>
                    </div>
                </div>
                <div class="reserve-area flex-box">
                    <!-- 備轉容量 -->
                    <div class="box reserve-box">
                    <div class="reserve-inner">
                        <h3 class="subtitle margin03 color-white">明日預估備轉容量率</h3>
                        <p class="reserve-status">供電充裕</p>
                    </div>
                    <div class="reserve-img">
                        <!-- <img src="images/index_weather03.png" alt=""> -->
                        <!-- bar -->
                        <div class="SemiCircleBar-box">
                        <div class="SemiCircleBar"></div>
                        <p class="min">0</p>
                        <p class="max">15</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <!-- area -->
            <div class="area bg-white margin02">
            <div class="padding03">
                <h2 class="boxtitle margin02">得標資訊填報</h2>
                <!-- input -->
                <div class="graphCheck-area a-center margin03">
                <div class="inner">
                    <div class="form-line">
                    <div class="form-item">
                        <span>得標價格</span>
                          <input type="number" class="w-m" v-model="winningPrice">
                        <span>元，</span>
                    </div>
                    <div class="form-item">
                        <span>抑低契約容量</span>
                        <input type="number" class="w-m" v-model="conistCapcity">
                        <span>kW</span>
                    </div>
                    </div>
                    <div class="form-line margin0">
                     <div class="form-item">
                        <span>執行期間</span>
                        <div class="inputbtn w-l">
                          <date-picker style="width:auto" placeholder="起始日期" v-model="beginDate" valueType="format" :disabled-date='disabledDate'></date-picker>
                        </div>
                    </div>
                    <div class="form-item">
                        <select class="w-s" v-model="cblSelectBeginHour">
                          <option v-for = "i in 22" :key = i :value="('0'+ i).slice(-2)">{{i}}</option>
                        </select>
                        <select class="w-s" v-model="cblSelectMin">
                          <option>00</option>
                          <option>15</option>
                          <option>30</option>
                          <option>45</option>
                        </select>
                        <span>&nbsp;~&nbsp;</span>
                        <span class="font-size-xxl weight-n color-blue">{{parseInt(cblSelectBeginHour) + 2}}:</span>
                        <span class="font-size-xxl weight-n color-blue">{{cblSelectMin}}</span>
                    </div>
                    <div class="form-item">
                        <span class="font-size-xxl weight-n" style="margin:5px">共 </span>
                        <span class="font-size-xxl weight-n color-blue">2</span>
                        <span class="color-blue">小時</span>
                    </div>
                    </div>
                    <div class="mobile margin"></div>
                </div>
                <div class="btnbox">
                    <a class="btn-border" @click="tableRander(false)">查詢</a>
                </div>
                </div>
                <!--  -->
                <h3 class="boxtitle margin02">抑低分配預估</h3>
                <!-- table -->
                <div class="tableframe margin02">
                <table class="trBg02 padding-small box-border font-size-l">
                    <tr class="tr-dark font-size-xl">
                    <th rowspan="2" class="weight-thin">機關名稱</th>
                    <th rowspan="2" class="weight-thin">契約容量<br/>(kW)</th>
                    <th rowspan="2" class="weight-thin border-right02-blue">目標<br/>抑低容量</th>
                    <th colspan="4" class="weight-thin">預估抑低量(kW)</th>
                    </tr>
                    <tr class="tr-mid font-size-l">
                    <th class="weight-thin" v-for ="(i, idx) in timeArr" :key="idx">{{i}}</th>
                    </tr>
                    <tr v-for ="(i, idx) in tableArr" :key="idx">
                      <td class="tL">{{i.ems}}</td>
                      <td>{{i.capacity}}</td>
                      <td class="border-right02-blue">{{Math.min(...minArr[idx])}}</td>
                      <td>{{i.filterArr[0].demand_15}}</td>
                      <td>{{i.filterArr[1].demand_15}}</td>
                      <td>{{i.filterArr[2].demand_15}}</td>
                      <td>{{i.filterArr[3].demand_15}}</td>
                    </tr>
                    <tr class="tr-gray">
                      <td colspan="3" class="bg-blue color-white-blue font-size-xl border-right02-blue"><p class="padding">合計</p></td>
                      <td class="color-blue font-size-xl weight-bold" v-for="(i, idx) in totalArr" :key='idx'>{{i}}</td>
                    </tr>
                </table>
                </div>
            </div>
            </div>
            <!-- area -->
            <div class="area bg-white">
            <div class="padding03">
                <h2 class="boxtitle margin02">需量反應抑低容量發布</h2>
                <!-- table -->
                <div class="tableframe margin02">
                <table class="trBg02 padding-small box-border font-size-l" ref="imageToFile">
                    <tr class="tr-dark font-size-xl">
                    <th rowspan="1" class="weight-thin border-right02-blue">機關名稱</th>
                    <th colspan="4" class="weight-thin">預估抑低量(kW)</th>
                    </tr>
                    <tr class="tr-mid font-size-l">
                      <th></th>
                    <th colspan="1" class="weight-thin" v-for ="(i, idx) in timeArr" :key="idx">{{i}}</th>
                    </tr>
                    <tr v-for ="(i, idx) in tableArr" :key="idx">
                      <td class="tL border-right02-blue">{{i.ems}}</td>
                      <td><input type="number" class="w-m tC" :v-model="i.filterArr[0].demand_15" :value="i.filterArr[0].demand_15" @input="i.filterArr[0].demand_15 = Number($event.target.value)" @change="getTotalArr"></td>
                      <td><input type="number" class="w-m tC" :v-model="i.filterArr[1].demand_15" :value="i.filterArr[1].demand_15" @input="i.filterArr[1].demand_15 = Number($event.target.value)" @change="getTotalArr"></td>
                      <td><input type="number" class="w-m tC" :v-model="i.filterArr[2].demand_15" :value="i.filterArr[2].demand_15" @input="i.filterArr[2].demand_15 = Number($event.target.value)" @change="getTotalArr"></td>
                      <td><input type="number" class="w-m tC" :v-model="i.filterArr[3].demand_15" :value="i.filterArr[3].demand_15" @input="i.filterArr[3].demand_15 = Number($event.target.value)" @change="getTotalArr"></td>
                    </tr>
                    <tr class="tr-gray">
                      <td class="bg-blue color-white-blue font-size-xl border-right02-blue"><p class="padding">預估總抑低量</p></td>
                      <td class="color-blue font-size-xl weight-bold" v-for="(i, idx) in totalArr" :key='idx'>{{i}}</td>
                    </tr>
                </table>
                </div>
                <div class="btnbox margin">
                <a class="btn-check" @click="Postbid" :style="{ background: buttonSpinnerColor }">儲存</a>
                <a class="btn-check bg-kahki-light" @click="screenShot">寄發通知郵件</a>
                </div>
            </div>
            </div>
        </div>
        </div>
        <!--  footer -->
        <footer>
        <div class="pagesize">
            <div class="footer-info">
            <div class="info-box info-main">
                <div class="logo"></div>
                <div class="copyright">
                <p><a href="#">政府網站資料開放宣告</a>&nbsp;|&nbsp;<a href="#">隱私權及網站安全政策</a></p>
                <p>Copyright © All Rights Reserved</p>
                </div>
            </div>
            <div class="info-box info-sub">
                <p>執行單位：(02)2586-5000 # 325 陳小姐</p>
                <div class="logo02"></div>
            </div>
            </div>
        </div>
        </footer>
    </div>
    </div>
</template>

<style scoped>
    @import './css/inner.css';
    .cursor > g{
      cursor: pointer;
    }
</style>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapState } from 'vuex'
import { countDown } from '../store/function/index'
import progressbar from './js/jquery.progressbar'
import importJQ from './js/main'
import { DateTime } from 'luxon'
import axios from 'axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import cookies from 'vue-cookies'
import Vue from 'vue'
// import html2canvas from 'html2canvas'
export default {
  components: { DatePicker },
  data () {
    return {
      updateTime: DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
      cblSelectBeginHour: '01',
      cblSelectEndHour: this.cblSelectBeginHour + 2,
      cblSelectMin: '00',
      CBLhhmm: '',
      beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
      bidnote: {
        title: '',
        do: ''
      },
      winningPrice: 10,
      conistCapcity: 100,
      tableArr: [],
      getBidArr: '',
      eventId: '',
      CBLTime: '',
      filterArr: [],
      timeArr: [],
      totalArr: [],
      postArr: [],
      minArr: [],
      buttonSpinnerColor: '#8f7200',
      postLoading: false
    }
  },
  methods: {
    ...mapMutations([
      'COUNTING_TIMER',
      'LOGIN_OUT'
    ]),
    async screenShot () {
      const el = this.$refs.imageToFile;
      const options = {
        type: 'dataURL'
      }
      const output = await this.$html2canvas(el, options)
      this.sendEmail(output)
    },
    sendEmail (base64) {
      const token = cookies.get('Authorization')
      axios.post('/api/cems/sendEmail',
        {
          imgB64: base64
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      Vue.swal('已寄發通知郵件')  
    },
    disabledDate (date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date > new Date(today.getTime() + 1 * 24 * 3600 * 1000)
    },
    countdownTimer () {
      if (this.CBLTime) {
        const cblDate = this.CBLTime.date
        const cblStartTime = this.CBLhhmm
        const cblString = cblDate + ' ' + cblStartTime
        const today = DateTime.local().toFormat('yyyy-MM-dd HH:mm')
        const time = cblStartTime.slice(0, 2)
        const minute = cblStartTime.slice(-2)
        const year = cblDate.slice(0, 4)
        const month = cblDate.slice(5, 7)
        const date = cblDate.slice(-2)
        if (today > cblString) {
          this.bidnote.title = '需量反應'
          this.bidnote.do = '投標'
          return countDown(11)
        } else {
          this.bidnote.title = '已得標'
          this.bidnote.do = '執行'
          return countDown(time, minute, year, month, date)
        }
      } else {
        this.bidnote.title = '需量反應'
        this.bidnote.do = '投標'
        return countDown(11)
      }
    },
    async reserverateProgress () {
      const date = DateTime.local().toFormat('yyyy-MM-dd')
      const data = await axios.get(`https://tpcems.tier.org.tw/api/tpcems/open/home/operating-reserve-by-date?date=${date}`)
      var barValue = data.data.operatingReserveRate // 設定數值顯示文字
      var bar = new progressbar.SemiCircle('.SemiCircleBar', {
        strokeWidth: 6,
        color: '#c0ff37',
        trailColor: '#fff',
        trailWidth: 6,
        easing: 'easeInOut',
        duration: 1000,
        svgStyle: null,
        text: {
          value: ''
          // alignToBottom: true
        },
        step: function (state, circle) {
          circle.setText(barValue)
        }
      })
      bar.animate(barValue / 15)
      // bar.animate(0.3) /* b設定bar參數  0~1.0 */
    },
    async tableRander (first) {
      first ? await this.getDefaultBid() : await this.getBid(first)
      if (this.getBidArr.success) {
        this.tableArr = []
        this.filterArr = []
        this.timeArr = []
        this.tableArr = this.getBidArr.body.detail
        for (let i = 0; i < this.tableArr.length; i++) {
          const index = this.tableArr[i].minReduceByTimeRange.findIndex(x => x.timeStart === `${this.cblSelectBeginHour}:${this.cblSelectMin}`)
          this.filterArr[i] = []
          for (let x = index; x < (index + 8); x = x + 2) {
            if (x === index + 6) {
              this.filterArr[i].push(this.tableArr[i].minReduceByTimeRange[x])
              this.filterArr[i].push(this.tableArr[i].minReduceByTimeRange[x + 1])
            } else {
              this.filterArr[i].push(this.tableArr[i].minReduceByTimeRange[x])
            }
          }
          this.tableArr[i].filterArr = this.filterArr[i]
        }
        const lastTimeEnd = this.tableArr[0].filterArr[4].timeEnd
        for (let i = 0; i < this.tableArr[0].filterArr.length; i++) {
          if (i + 1 === this.tableArr[0].filterArr.length - 1) {
            this.timeArr.push(`${this.tableArr[0].filterArr[i].timeStart}-${lastTimeEnd}`)
          } else {
            if (i < 4) this.timeArr.push(`${this.tableArr[0].filterArr[i].timeStart}-${this.tableArr[0].filterArr[i + 1].timeStart}`)
          }
        }
        for (let i = 0; i < this.tableArr.length; i++) {
          this.tableArr[i].filterArr.pop()
        }
        this.getTotalArr()
        this.getMinArr()
      }
    },
    Postbid () {
      if (!this.postLoading) {
        this.buttonSpinnerColor = 'gray'
        this.postLoading = true
        for (let i = 0; i < this.tableArr.length; i++) {
          for (let j = 0; j < this.tableArr[i].filterArr.length; j++) {
            const whichArr = this.tableArr[i].minReduceByTimeRange.findIndex(x => x.timeStart === this.tableArr[i].filterArr[j].timeStart)
            this.tableArr[i].minReduceByTimeRange[whichArr] = this.tableArr[i].filterArr[j]
          }
        }
        this.postArr = JSON.parse(JSON.stringify(this.getBidArr))
        for (let i = 0; i < this.postArr.body.detail.length; i++) {
          delete this.postArr.body.detail[i].filterArr
        }
        this.postArr.body.execTime = `${this.cblSelectBeginHour}:${this.cblSelectMin}`
        this.postArr.body.winBid = Number(this.winningPrice)
        this.postArr.body.winReduceCap = Number(this.conistCapcity)
        this.postArr.body.eventId = this.eventId
        this.updateBid()
      }
    },
    getMinArr () {
      this.minArr = []
      for (let i = 0; i < this.tableArr.length; i++) {
        this.minArr[i] = []
        for (let j = 0; j < this.tableArr[i].filterArr.length; j++) {
          this.minArr[i].push(this.tableArr[i].filterArr[j].demand_15)
        }
      }
    },
    getTotalArr () {
      this.totalArr = []
      for (let i = 0; i < this.tableArr[0].filterArr.length; i++) {
        this.totalArr[i] = 0
      }
      for (let i = 0; i < this.tableArr.length; i++) {
        for (let j = 0; j < this.tableArr[0].filterArr.length; j++) {
          this.totalArr[j] = Number(this.totalArr[j]) + Number(this.tableArr[i].filterArr[j].demand_15)
        }
      }
      this.getMinArr()
    },
    async getBid () {
      const token = cookies.get('Authorization')
      const getBid = await axios.post('/api/cems/getBid',
        {
          queryDate: this.beginDate
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (getBid.data.success) {
        this.getBidArr = getBid.data
        this.cblSelectBeginHour = getBid.data.body.execTime.slice(0, 2)
        this.cblSelectMin = getBid.data.body.execTime.slice(-2)
        this.CBLTime.date = getBid.data.body.execDate
        this.conistCapcity = getBid.data.body.reduceCapacity
        this.eventId = getBid.data.body.eventId
        this.winningPrice = getBid.data.body.winBid
      } else {
        Vue.swal('查無資料')
      }
    },
    async getDefaultBid () {
      const token = cookies.get('Authorization')
      const getDefaultBid = await axios.post('/api/cems/getDefaultBid',
        {
          queryDate: this.beginDate
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (getDefaultBid.data.success) {
        this.beginDate = getDefaultBid.data.body.execDate
        this.getBidArr = getDefaultBid.data
        this.eventId = getDefaultBid.data.body.eventId
        this.CBLhhmm = getDefaultBid.data.body.execTime
        this.cblSelectBeginHour = getDefaultBid.data.body.execTime.slice(0, 2)
        this.cblSelectMin = getDefaultBid.data.body.execTime.slice(-2)
        this.conistCapcity = getDefaultBid.data.body.reduceCapacity
        this.winningPrice = getDefaultBid.data.body.winBid
      }
    },
    async updateBid () {
      const token = cookies.get('Authorization')
      await axios.put('/api/cems/updateBid',
        {
          bidObj: this.postArr.body
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      this.buttonSpinnerColor = '#8f7200'
      this.postLoading = false
      this.getCBLTime()
      Vue.swal('儲存成功')
    },
    async getCBLTime () {
      const token = cookies.get('Authorization')
      const CBLTime = await axios.post('/api/cems/winBid15Dmd',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (CBLTime.data.success) {
        this.CBLTime = CBLTime.data.dataDetail
      }
    }
  },
  mounted () {
    importJQ()
    this.getCBLTime()
    this.reserverateProgress()
    this.tableRander(true)
  },
  computed: {
    ...mapGetters([
      'getTimenow'
    ]),
    ...mapState([
      'login',
      'navbar',
      'weather',
      'pop',
      'reserverate',
      'currentWeather',
      'nextdayWeather'
    ])
  }
}
</script>
